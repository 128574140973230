import {
  type MetaArgs_SingleFetch,
  Outlet,
  useRouteLoaderData,
} from '@remix-run/react'
import Alert from '~/components/Alert'

import { Header } from '~/components/Header'
import { ToggleAffiliateOffers } from '~/components/ToggleAffiliateOffers'
import type { loader as RootLoader } from '~/root'
import * as accountUtils from '~/utils/accounts'
import { getSocialMetas } from '~/utils/utils'

export function meta({ location }: MetaArgs_SingleFetch) {
  return [
    ...getSocialMetas({
      url: `https://buy.loungepair.com${location.pathname}`,
    }),
  ]
}

export const handle = {
  getSitemapEntries: () => {
    return null
  },
}

export default function Directory() {
  return (
    <div className="max-w-3xl mx-auto h-screen my-6">
      <Header />
      <h1 className="mb-1 p-4 font-bold text-h1">
        Buy access to over 900+ airport lounges
      </h1>
      <div className="mx-4 mb-8">
        <ToggleAffiliateOffers />
      </div>

      {/* {accountUtils.hasActiveSubscription(root?.account) ? (
        <div className="mb-6">
          <Alert
            message={`As a LoungePair+ member, prices for LoungePair offers will be converted to ${root?.account?.currency}.`}
          />
        </div>
      ) : null} */}

      <Outlet />
    </div>
  )
}
